<i18n>
{
	"en": {
		"pendingApplicationPeriods": "If you'd like to make changes to your applications, you must cancel existing application and then submit a new one.",
		"pendingApplicationPeriodsLink": "Applications",
		"noApplicationPeriods": "There are currently no open application periods."
	},
	"fi": {
		"pendingApplicationPeriods": "Mikäli haluat tehdä muutoksia hakemukseesi, sinun tulee perua aiempi hakemus ja täyttää hakemuslomake uudelleen.",
		"pendingApplicationPeriodsLink": "Hakemukset",
		"noApplicationPeriods": "Hakuaika ei ole tällä hetkellä käynnissä."
	}
}
</i18n>

<template>
	<div>
		<v-container
			v-if="ready === false"
			class="container--narrow"
		>
			<Spinner />
		</v-container>

		<v-container
			v-else-if="ready === true"
			class="container--narrow"
		>
			<v-alert
				v-if="!data.application_periods || (data.application_periods && !data.application_periods.open.length && !data.application_periods.pending.length)"
				type="warning"
				border="top"
				colored-border
				elevation="2"
				class="multi-line mb-6"
			>
				{{ $i18n.t('noApplicationPeriods') }}
			</v-alert>

			<div
				v-if="data.application_periods && data.application_periods.pending.length"
			>
				<v-alert
					type="info"
					class="multi-line mb-6"
				>
					{{ $i18n.t('pendingApplicationPeriods') }}
					<v-spacer />
					<v-btn
						color="primary"
						class="mt-5"
						:to="({ name: 'resortApplications' })"
					>
						{{ $i18n.t('pendingApplicationPeriodsLink') }}
					</v-btn>
				</v-alert>
			</div>

			<div
				v-if="data.application_periods && data.application_periods.open.length"
				class="resorts-form-container"
			>
				<v-alert
					v-if="data.application_info"
					type="info"
					border="top"
					colored-border
					elevation="2"
					class="multi-line mb-6"
				>
					<span v-html="data.application_info" />
				</v-alert>
				<v-card v-if="data.application_form">
					<v-card-text>
						<SchemaToForm
							v-model="formData"
							:schema="formSchema"
							:disabled="saveLoading"
							:debug="false"
							class="pt-5"
							@valid="formValid = true"
							@invalid="formValid = false"
							@change="$store.commit('setConfirmNavigation', true)"
						/>
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn
							color="primary"
							:loading="saveLoading"
							:disabled="saveLoading || !formValid"
							@click="save"
							text
						>
							{{ $i18n.t('general.save') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</div>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'ResortsForm',
	data: () => ({
		ready: false,
		saveLoading: false,
		formValid: false,
		formSchema: {},
		formData: {},
		selectableResorts: [],
	}),
	computed: {
		...mapState({
			user: state => state.user,
			data: state => state.resortsData,
		}),
		petFields () {
			if (!this.formSchema || !this.formSchema.fields) return []

			return this.formSchema.fields.filter(field => ['c_lemmikkielain', 'c_voi_majoittua_lemmikkielainhuoneistoon'].includes(field.name))
		},
	},
	watch: {
		'formData.c_haettu_lomakohde': {
			handler (selectedResorts, prevSelectedResorts) {
				if (selectedResorts === prevSelectedResorts) return
				const resortsByName = this.formSchema.fields.find(f => f.name === 'c_haettu_lomakohde').props.items.reduce(function (map, selectedResortTitles) {
					map[selectedResortTitles.name] = selectedResortTitles.title
					return map
				}, {})
				const selectedResortTitles = []
				for (const selectedResort of selectedResorts) {
					selectedResortTitles.push(resortsByName[selectedResort])
				}
				for (const item of this.formSchema.fields.find(f => f.name === 'c_ensisijainen_lomapaikka').props.items) {
					const isDisabled = !selectedResortTitles.includes(item.title)
					this.$set(item, 'disabled', isDisabled)
					this.$set(item, 'hidden', isDisabled)
					if (isDisabled && this.formData.c_ensisijainen_lomapaikka == item.name) {
						this.formData.c_ensisijainen_lomapaikka = ''
					}
				}

				// Pet fields should be visible only when 'Ylläs' is selected as resort
				for (const field of this.petFields) {
					this.$set(field, 'hidden', !selectedResorts.includes('o_2498'))
				}
			},
			deep: true,
		},
		'formData.c_mokkihakuaika': {
			handler (selectedPeriod, prevSelectedPeriod) {
				if (selectedPeriod === prevSelectedPeriod) return
				const periodDates = this.data.application_periods.open.reduce(function (map, periods) {
					map[periods.name] = periods.dates_between
					return map
				}, {})
				if (periodDates && periodDates[selectedPeriod] && periodDates[selectedPeriod].length === 2) {
					const dateFrom1 = this.formSchema.fields.find(f => f.name === 'c_ensisijainen_lomaviikko')
					dateFrom1.pickerProps.min = periodDates[selectedPeriod][0]
					dateFrom1.pickerProps.max = periodDates[selectedPeriod][1]
					dateFrom1.pickerProps['show-current'] = periodDates[selectedPeriod][0]
					const dateFrom2 = this.formSchema.fields.find(f => f.name === 'c_toissijainen_lomaviikko')
					dateFrom2.pickerProps.min = periodDates[selectedPeriod][0]
					dateFrom2.pickerProps.max = periodDates[selectedPeriod][1]
					dateFrom2.pickerProps['show-current'] = periodDates[selectedPeriod][0]
				}
			},
		},
	},
	async mounted () {
		const response = await this.$api.Resorts.doRequest()
		this.formData = response.result.body.resortsData.application_form_data
		this.formSchema = response.result.body.resortsData.application_form

		await this.$nextTick()

		this.ready = true
	},
	methods: {
		save () {
			this.saveLoading = true

			this.$api.ResortApplicationsAdd.doRequest({
				method: 'POST',
				body: this.formData,
			}).on('done', (res) => {
				// Since the register may reformat values (i.e. telephone number),
				// replace form data with server response.
				if (res.body.formData) {
					this.formData = res.body.formData
				}

				// Reload resort applications
				this.$api.ResortApplications.doRequest().then(() => {

					// Clear navigation confirm
					if (!res.body.error){
						this.$nextTick(() => {
							this.$store.dispatch('clearConfirmNavigation')
							this.$router.replace({
								name: 'resortApplications',
								params: {
									success: true,
								},
							})
						})
					}
				})
			}).on('finish', () => {
				this.saveLoading = false
			})
		},
	},
}
</script>

<style lang="scss">
.resorts-form-container {

	.v-input--radio-group__input legend {
		padding-bottom: .75rem;
		width: 100%
	}

	.v-messages__message {
		line-height: 1.5;
		font-size: .875rem;
		margin-top: 0.5rem;
	}
}

/*
hack: show-current is used to force datepicker to start from first available month, and this
style is used to hide the fact that we're displaying (fake) current date
*/
.resorts-picker .v-date-picker-table .v-btn--outlined {
	border: 0;
	color: black !important;
}
</style>
